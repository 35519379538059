<template>
  <b-sidebar
    v-model="isSidebarActiveLocal"
    variant="primary"
    bg-variant="white"
    right
    width="500px"
    backdrop
    shadow
  >
    <div class="d-flex justify-content-center justify-between">
      <h4>Edit Billing Contact Info</h4>
    </div>
    <hr class="mb-0">

    <b-row class="mx-2 my-1">
      <b-col class="w-50">
        <p class="font-bold">
          First Name
        </p>
        <b-form-input
          v-model="data.first_name"
          class="w-full"
        />
      </b-col>
      <b-col class="w-50">
        <p class="font-bold">
          Last Name
        </p>
        <b-form-input
          v-model="data.last_name"
          class="w-full"
        />
      </b-col>
    </b-row>
    <b-row class="mx-2 my-1">
      <b-col class="w-100">
        <p class="font-bold">
          Company Position
        </p>
        <b-form-input
          v-model="data.position_in_company"
          class="w-full"
        />
      </b-col>
    </b-row>

    <b-row class="mx-2 my-1">
      <b-col class="w-100">
        <p class="font-bold">
          Address
        </p>
        <b-form-input
          v-model="data.address_1"
          required
          class="w-full"
        />
      </b-col>
    </b-row>

    <b-row class="mx-2 my-1">
      <b-col class="w-100">
        <p class="font-bold">
          Address 2
        </p>
        <b-form-input
          v-model="data.address_2"
          class="w-full"
        />
      </b-col>
    </b-row>

    <b-row class="mx-2 my-1">
      <b-col class="w-50">
        <p class="font-bold">
          City
        </p>
        <b-form-input
          v-model="data.city"
          class="w-full"
        />
      </b-col>
      <b-col class="w-50">
        <p class="font-bold">
          State / Province
        </p>
        <b-form-input
          v-model="data.province"
          class="w-full"
        />
      </b-col>
    </b-row>

    <b-row class="mx-2 my-1">
      <b-col class="w-50">
        <p class="font-bold">
          Zip / Postal code
        </p>
        <b-form-input
          v-model="data.postal_code"
          class="w-full"
        />
      </b-col>
      <b-col class="w-50">
        <p class="font-bold">
          Country
        </p>
        <v-select
          v-model="data.country"
          placeholder="Choose your country"
          :options="allCountries"
          :reduce="value => value.value"
          label="value"
          class="selectExample w-full"
        />
      </b-col>
    </b-row>

    <b-row class="mx-2 my-1">
      <b-col class="w-50">
        <p class="font-bold">
          Email Address (all invoices and receipts will be sent here)
        </p>
        <b-form-input
          v-model="data.email_address"
          required
          class="w-full"
        />
      </b-col>
    </b-row>

    <b-row class="mx-2 my-1">
      <b-col class="w-50">
        <p class="font-bold">
          Phone Number
        </p>
        <b-form-input
          v-model="data.phone_number"
          required
          class="w-full"
        />
      </b-col>
    </b-row>
    <div class="d-flex justify-content-between mb-3 mt-1 mx-2">
      <b-col class="w-50">
        <b-button
          class="w-100"
          variant="outline-warning"
          @click="isSidebarActiveLocal = false"
        >Cancel Changes</b-button>
      </b-col>


      <b-col class="w-50">
        <ButtonSpinner :is-loading="isButtonLoading.updateBillingInfo">
          <b-button
            class="w-100"
            variant="primary"
            @click="updateBillingInfo"
          >Save Changes</b-button>
        </ButtonSpinner>
      </b-col>
    </div>

  </b-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BSidebar, VBToggle,
  BCard,
  BFormInput,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
  BBadge,
  BAvatar,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BFormSelect,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { allCountries } from '@/constants/datastore'

export default {
  components: {

    BSidebar,
    BFormInput,
    BCard,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BModal,
    VBModal,
    BBadge,
    BAvatar,
    BOverlay,
    BPagination,
    BFormCheckbox,
    BFormSelect,
    BFormInvalidFeedback,
    VBToggle,
    VuePerfectScrollbar,

    'v-select': vSelect,
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isButtonLoading: {
        all: false,
        updateBillingInfo: false,
      },
      dataId: null,
      dataName: '',
      dataCategory: null,
      dataImg: null,
      dataOrder_status: 'pending',
      dataPrice: 0,
      popupApplicant: false,
      applicant_info: {},
      allCountries,
      category_choices: [
        { text: 'Audio', value: 'audio' },
        { text: 'Computers', value: 'computers' },
        { text: 'Fitness', value: 'fitness' },
        { text: 'Appliance', value: 'appliance' },
      ],

      order_status_choices: [
        { text: 'Pending', value: 'pending' },
        { text: 'Canceled', value: 'canceled' },
        { text: 'Delivered', value: 'delivered' },
        { text: 'On Hold', value: 'on_hold' },
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      },
    },
    isFormValid() {
      return (
        !this.errors.any()
        && this.dataName
        && this.dataCategory
        && this.dataPrice > 0
      )
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
      }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  methods: {
    more_info(details) {
      this.applicant_info = details
      this.popupApplicant = true
    },
    updateBillingInfo() {
      const all_data = this.data

      this.isButtonLoading.updateBillingInfo = true
      this.$http
        .post('/api/billing-info', all_data)
        .then(response => {
          this.isButtonLoading.updateBillingInfo = false
          if (response.data.success) {
            this.data = response.data
            this.$toastSuccess('Billing Notification', 'Your billing information has been updated successfully')
            this.$emit('closeSidebar')
          }
        })
        .catch(error => {
          this.isButtonLoading.updateBillingInfo = false
          this.$toastDanger('Billing Notification', 'Failed updating your billing information')
        })
    },
  
    initValues() {
      if (this.data.id) return
      this.dataId = null
      this.dataName = ''
      this.dataCategory = null
      this.dataOrder_status = 'pending'
      this.dataPrice = 0
      this.dataImg = null
    },
    submitData() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$emit('closeSidebar')
          this.initValues()
        }
      })
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.dataImg = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
